<script setup>

import { sendSignInLinkToEmail, signOut } from "firebase/auth";
import { getAuth, tenantDatabase } from "@/tenant";
import { getDatabase, ref, get, child, onValue } from "firebase/database";
import getFirebaseConfig from "../../helpers/GetFirebaseConfig";
import sortObj from '../../helpers/SortObjectHelper';
import axios from "axios";

import store from "../../store";
import allRoles from "../../data/alluserRoles";

import Container from '../containers/Container.vue';
import Loading from '../containers/Loading.vue';
import Header from '../objects/Header.vue';
import AuthValidation from '../containers/AuthValidation.vue';
import PermissionsValidation from '../containers/PermissionsValidation.vue';
import Loader from '../objects/Loader.vue';
import NoResultFound from '@/components/objects/NoResultFound.vue';
import UserDropdown from '../objects/UserDropdown.vue';
import ChevronLeft from '~icons/my-icons/chevron-left';
import ChevronRight from '~icons/my-icons/chevron-right';
import DeleteUserConfirmation from '../DeleteUserConfirm.vue';
import Notification from '../objects/Notification.vue';
import UserAvatar from '../objects/UserAvatar.vue';
import SendEmailModal from '../SendEmailModal.vue';

    import Fuse from 'fuse.js';

import TeamValueToLabel from '../../helpers/TeamValueToLabel';
import AccountSettingsValueToLabel from '../../helpers/AccountSettingsValueToLabel';
import ProjectValueToLabel from '../../helpers/ProjectValueToLabel';
import ConvertTimeHelper from '../../helpers/ConvertTimeHelper';
import PermissionHelpers from '../../helpers/PermissionHelpers';
import { Skeletor } from "vue-skeletor";

import SearchIcon from '~icons/my-icons/search';
import IconTick from '~icons/my-icons/tick';
import UserPlusIcon from '~icons/my-icons/user-plus';
import PlusClose from '~icons/my-icons/cross';
import IconArrowDown from '~icons/my-icons/sort-down';
import CopyIcon from '~icons/my-icons/copy';
import CheveronDown from '~icons/my-icons/chevron-down';
import PersonIcon from '~icons/my-icons/person-icon';
import TickIcon from '~icons/my-icons/tick';

</script>

<template>
    <div class="pb-20 bg-white flex-1 ml-[196px] relative">
        <div class="py-12 bg-white ">
            <Container class="w-full flex justify-between items-center">
                <h1 class="text-3xl font-bold">Contacts</h1>
                <div class="flex relative gap-3">
                    <div class="relative">
                        <SearchIcon class="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none" />
                        <input type="text" :value="query" @input="query = $event.target.value"
                            class="block rounded border border-bd w-[300px] text-ellipsis px-12 py-2.5 h-12"
                            placeholder="Search contacts" />
                        <button v-if="query.length > 0" @click="() => query = ''"
                            class="absolute top-1/2 right-4 -translate-y-1/2">
                            <PlusClose class="w-3" />
                            <span class="sr-only">Clear search</span>
                        </button>
                    </div>
                    <div class="relative" v-click-outside-element="close">
                        <div class="flex items-center px-4  rounded border border-bd w-[300px] text-ellipsis py-2.5 h-12">
                            <p @click="showDetails" class="cursor-pointer w-[248px] truncate">{{ selectedTeam ? selectedTeam.name : "All clients" }}</p>
                            <CheveronDown @click="showDetails" :class="showModal && 'rotate-180', selectedTeam && 'hidden'" class="h-[20px] w-[20px] ml-3 cursor-pointer" />
                            <PlusClose @click="clearSelection" :class="selectedTeam ? 'block' : 'hidden'" class="w-3 cursor-pointer" />
                        </div>
                        <div v-if="showModal" class="min-w-[300px] max-w-[300px] absolute left-3 top-[50px]">
                            <div class="bg-white flex row border border-gray-900 w-full">
                                <ul class="pl-2 max-w-[480px] max-h-[204px] overflow-x-hidden overflow-y-auto">
                                <li @click="clearSelection" :class="!selectedTeam ? 'bg-[#FF4B40] text-white hover:bg-[#FF4B40] hover:text-grey-800' : ''" class="p-2 cursor-pointer hover:bg-gray-100 active:bg-[#FF4B40] active:text-white leading-7 rounded-[4px] my-2 truncate">
                                    All clients
                                </li>
                                <li v-for="client in filteredClients" v-if="firebaseClients" @click="filterByTeam(client)" :key="client.clientId" :class="client == selectedTeam ? 'bg-[#FF4B40] text-white hover:bg-[#FF4B40] hover:text-grey-800' : ''" class="p-2 cursor-pointer hover:bg-gray-100 active:bg-[#FF4B40] active:text-white leading-7 rounded-[4px] my-2 truncate">
                                    {{ client.name }}
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <router-link v-if="PermissionHelpers.CanAddUsers()" :to="{ name: 'Admin - Add new contact' }"
                        class="relative flex items-center bg-orange-500 rounded text-white py-2.5 px-5">
                        <UserPlusIcon class="pointer-events-none w-5 h-5 mr-2 text-[0.6rem]" />
                        <span>Add new contact</span>
                    </router-link>
                    <button @click="() => sendEmailModalActive = true"
                        class="block bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center">
                        Send email
                    </button>
                </div>
            </Container>
        </div>
        <Container>
            <div class="">
                <Notification :type="'relative'" :notificationType="notifications" />
                <div class="rounded-lg border border-solid mb-8">
                    <Loading :isLoading="isLoading" type="overlay">
                        <table class="w-full">
                            <thead class="border-none">
                                <tr class="bg-gray-50 border-b border-gray-200">
                                    <th class="rounded-tl-lg">
                                        <button
                                            class="block px-8 py-4 w-full text-left text-purple-900 text-xs font-semibold"
                                            @click="handleSortBy('name')">
                                            Name
                                            <span v-if="sort.sortBy === 'name'" class="text-[10px] inline-block ml-2"
                                                :class="{ 'rotate-180': sort.direction === 'desc' }">
                                                <IconArrowDown />
                                            </span>
                                        </button>
                                    </th>
                                    <th class="">
                                        <button
                                            class="w-[148px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold"
                                            @click="handleSortBy('team')">Client
                                            <span v-if="sort.sortBy === 'team'" class="text-[10px] inline-block ml-2"
                                                :class="{ 'rotate-180': sort.direction === 'desc' }">
                                                <IconArrowDown />
                                            </span></button>
                                    </th>
                                    <th class=" w-[288px]">
                                        <span
                                            class="block px-2 py-4 w-full text-left text-purple-900 text-xs font-semibold"
                                        >Retainer(s)</span>
                                    </th>
                                    <th class="">
                                        <button
                                            class="w-[168px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold"
                                            @click="handleSortBy('accountSettings')">Account
                                            <span v-if="sort.sortBy === 'accountSettings'"
                                                class="text-[10px] inline-block ml-2"
                                                :class="{ 'rotate-180': sort.direction === 'desc' }">
                                                <IconArrowDown />
                                            </span></button>
                                    </th>
                                    <th class="">
                                        <button
                                            class="w-[108px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold"
                                            @click="handleSortBy('lastActive')">Last active
                                            <span v-if="sort.sortBy === 'lastActive'" class="text-[10px] inline-block ml-2"
                                                :class="{ 'rotate-180': sort.direction === 'desc' }">
                                                <IconArrowDown />
                                            </span></button>
                                    </th>
                                    <th class="w-[96px]  rounded-tr-lg">
                                        <!-- Options -->
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="isLoading" class="border-b border-solid last:border-none">
                                <tr>
                                    <td class="space-y-1" colSpan="6">
                                        <Skeletor height="80" width="100%" />
                                        <Skeletor height="80" width="100%" />
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else-if="filteredList.length > 0" class="">
                                <tr v-for="user in filteredList" :key="user.item.userSettings.email"
                                    class="border-b border-solid last:border-none group">
                                    <td class="bg-white pl-4 pr-2 py-3 text-xs group-last:rounded-bl-lg">
                                        <div class="inline-flex items-center">
                                            <router-link class="inline-flex items-center"
                                                :to="{ name: 'Admin - User profile', params: { userid: user.item.uid } }">
                                                <span
                                                    class="mr-4 rounded-full overflow-hidden flex-shrink-0 min-w-[64px] min-h-[64px] inline-flex items-center justify-center bg-center bg-cover bg-black"
                                                    :style="{ backgroundImage: `url(${user.item.userSettings.profileImage})` }">
                                                    <UserAvatar v-if="!user.item.userSettings.profileImage"
                                                        :name="`${user.item.userSettings?.firstName} ${user.item.userSettings?.lastName}`" />
                                                </span>
                                            </router-link>
                                            <div>
                                                <router-link class="inline-flex items-center"
                                                    :to="{ name: 'Admin - User profile', params: { userid: user.item.uid } }">
                                                    <span class="mb-1 text-grey-900 text-xs block">{{
                                                        user.item.userSettings?.firstName }}
                                                        {{ user.item.userSettings?.lastName }}</span>
                                                </router-link>
                                                <span class="flex items-center text-grey-400 text-xs group cursor-pointer"
                                                    @click="handleCopyEmail(user.item.userSettings?.email)">{{
                                                        user.item.userSettings?.email }}
                                                    <CopyIcon
                                                        class="ml-2 opacity-0 hover:text-blue-500 text-grey-900 group-hover:opacity-100">
                                                    </CopyIcon>
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="bg-white px-2 py-3 text-xs">
                                        <router-link
                                            :to="{name: 'Admin - Beta clients', query: { viewing: user.item.userSettings.client || null } }"
                                            class="hover:underline"
                                        >
                                            {{ getTeamClientName(user.item.uid) }}
                                        </router-link>
                                    </td>
                                    <td class="bg-white px-2 py-3 text-xs">
                                        <div v-if="firebaseClients.filter(client => !client.archived).some(client => client.clientId === user.item.userSettings.client && client.retainers && Object.keys(client.retainers).length > 0)">
                                            <div v-for="(client, index) in firebaseClients.filter(client => client.clientId === user.item.userSettings.client)" :key="client.clientId" class="">
                                                <div v-if="client.retainers && Object.keys(client.retainers).length > 0">
                                                    <template v-for="(retainer, retainerId, retainerIndex) in client.retainers" 
                                                            :key="retainerId">
                                                        <router-link
                                                            class="inline-flex items-center hover:underline"
                                                            :to="{name: 'Retainers Overview', params: { date: 'latest' }, query: { search: getRetainerValue(retainerId) } }">
                                                            <span>
                                                                <span>
                                                                    <span v-if="getRetainerValue(retainerId)">{{ getRetainerValue(retainerId) }}</span>
                                                                    <span class="inline-block" v-if="retainerIndex != Object.keys(client.retainers).length - 1">,&nbsp;</span>
                                                                </span>
                                                            </span>
                                                        </router-link>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <span v-else>N/A</span>
                                    </td>
                                    <td class="bg-white px-2 py-3 text-xs">
                                        <div v-for="setting in user.item.userSettings.accountSettings" :key="setting">
                                            <div class="flex items-center mb-1 last:mb-0"
                                                v-if="setting !== 'receive-monthly-report' && setting !== 'can-add-users'">
                                                <template v-if="setting === 'primary-contact' || 'it-contact'">
                                                    <PersonIcon class="mr-2.5 w-3" />
                                                </template>
                                                <template v-else>
                                                    <TickIcon class="mr-2.5 w-3" />
                                                </template>
                                                <div>
                                                    {{ AccountSettingsValueToLabel(setting) }}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div v-if="user.item.userSettings.userCanLogin" class="flex items-center">
                                            <TickIcon class="mr-2.5 w-3" />
                                            {{ user.item.userSettings.userCanLogin ? 'User can login to portal' : '' }}
                                        </div>
                                        <div></div> -->
                                    </td>
                                    <td :class="!user.item.userSettings?.lastLoginTime ? 'text-gray-400' : ''"
                                        class="bg-white px-2 py-3 text-xs">
                                        {{ user.item.userSettings?.lastLoginTime ? (user.item.userSettings?.lastLoginTime) :
                                            'Registration pending' }}
                                    </td>
                                    <td class="bg-white py-3 text-xs text-right group-last:rounded-br-lg">
                                        <div class="inline-flex items-center px-4">
                                            <UserDropdown type="admin" value="view" :userData="user.item"
                                                @handleCopyEmail="handleCopyEmail(user.item.userSettings?.email)"
                                                @handleDeleteModal="handleDeleteUserModal"
                                                @handleResendConfirmation="sendConfirmationMail(user.item)" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <!-- <tbody v-else-if="filterUsers.length === 0 && checkForClientUsers.length > 0">
                                <tr class="border-b border-solid last:border-none">
                                    <td class="bg-white py-8  gap-3 text-xs text-center" colspan="6">
                                        <p class="pb-3">No contacts found</p>
                                        <button @click="handleFullReset" class="inline-flex border text-xs border-gray-200 items-center py-1.5 px-4 rounded bg-gray-50 cursor-pointer">
                                            Reset filters
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else-if="checkForClientUsers.length === 0">
                                <tr class="border-b border-solid last:border-none">
                                    <td class="bg-white py-8  gap-3 text-xs text-center" colspan="6">
                                        <p class="pb-3">No contacts found.</p>
                                        <router-link v-if="PermissionHelpers.CanAddUsers()" :to="{ name: 'Admin - Add new contact' }"
                                            class="relative inline-flex items-center bg-orange-500 rounded text-white py-2.5 px-5">
                                            <UserPlusIcon class="pointer-events-none w-5 h-5 mr-2 text-[0.6rem]" />
                                            <span>Add new contact</span>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody> -->
                        </table>
                    </Loading>
                    <NoResultFound :counts="filteredList.length" :searchTerm="query" :handleFullReset="handleFullReset"
                        pageName="contacts" />
                        
                    <div v-if="filteredList.length == 0" class="items-center justify-center">                        
                        <div v-if="PermissionHelpers.CanAddUsers() && selectedTeam" class="relative flex px-12 mb-6 text-center font-medium items-center justify-center">
                            <router-link :to="{ name: 'Admin - Add new contact' }"
                                class=" flex bg-orange-500 rounded text-white py-2.5 px-5">
                                <UserPlusIcon class="pointer-events-none w-5 h-5 mr-2 text-[0.6rem]" />
                                <span>Add new contact</span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div v-if="paginationPageCount > 1" class="flex items-center justify-between">
                    <button class="mr-3 disabled:text-grey-300 text-sm bg-gray-50" :disabled="paginationPage == 1"
                        @click="handlePaginationPrevNext(true)">
                        <span class="sr-only">Previous page</span>
                        <div class="flex items-center justify-center rounded border border-gray-200 py-2 px-5">
                            <ChevronLeft />
                            <p class="ml-2">Prev</p>
                        </div>
                    </button>
                    <div>
                        <button class="mx-2"
                            :class="{ 'font-bold w-10 h-10 bg-black rounded-full text-white': index === paginationPage }"
                            v-for="index in paginationPageCount" :key="index" @click="handlePaginationSelect(index)">
                            {{ index }}
                        </button>
                    </div>
                    <button class="ml-3 disabled:text-grey-300 text-sm bg-gray-50"
                        :disabled="paginationPage == paginationPageCount" @click="handlePaginationPrevNext(false)">
                        <span class="sr-only">Next page</span>
                        <div class="flex items-center justify-center rounded border border-gray-200 px-5 py-2">
                            <p class="pr-2">Next</p>
                            <ChevronRight />
                        </div>
                    </button>
                </div>
            </div>
        </Container>
        <SendEmailModal @handleModalClose="handleSendEmailModalClose" :isActive="sendEmailModalActive"
            :allUsers="filteredList" />
        <DeleteUserConfirmation :isActive="deleteModalActive" :selectedUser="selectedUser"
            @handleModalClose="handleDeleteModalClose" />
    </div>
    <router-view></router-view>
</template>

<script>
import { initializeApp } from "firebase/app";
import teamValueToLabel from "../../helpers/TeamValueToLabel";
export default {
    data() {
        return {
            query: this.propsQuery ? this.propsQuery : '',
            allUsers: [],
            sort: [],
            allProjectsList: [],
            currentUser: null,
            firebaseApp: null,
            isLoading: true,
            deleteModalActive: false,
            selectedUser: {},
            notifications: this.notification ? this.notification : '',
            paginationPage: 1,
            itemsPerPage: 10,
            sendEmailModalActive: false,
            showModal: false,
            selectedTeam: null,
            firebaseClients: [],
            oauth: null
        }
    },
    props: ['notification', 'propsQuery'],
    watch: {
        selectedTeam() {
            // When selectedTeam changes, set paginationPage to 1
            this.paginationPage = 1;
        },
        query() {
            // When query changes, set paginationPage to 1
            this.paginationPage = 1;
        },
        checkForClientUsers() {
        }
        // Other watchers...
    },
    computed: {
        checkForClientUsers() {
            return this.allUsers.filter(user => user.userSettings.permission == 'client-admin' || user.userSettings.permission === 'client-user')
        },
        getTeamClientName() {
            return (userId) => {
                
                const user = this.filterUsers.find(user => user.item.uid === userId);
                if (user && user.item.userSettings.client) {
                    const userClientId = user.item.userSettings.client;
                    const client = this.firebaseClients.find(client => client.clientId === userClientId && !client.archived);
                    return client ? `${client.name}` : '';
                }
                return '';
            }
        },
        filteredClients() {
            return this?.firebaseClients?.filter(client => !client.archived)?.sort(sortObj("name")) || {}
        },
        uniqueTeams() {
        // Get unique non-blank teams from allUsers
        return Array.from(new Set(this.allUsers.map(user => user.item.userSettings.team)))
            .filter(team => team !== ''); // Ensure team is a non-empty string
        },
        isClient() {
            const currentUserPermission = store.getters.getUserState.permission;
            const viewAsClient = store.getters.getViewAsClient;
            if (viewAsClient) {
                return true
            }
            return currentUserPermission == 'client-admin' || currentUserPermission == 'client-user';
        },
        filteredList() {
            const { paginationPage, itemsPerPage } = this;
            const { sortBy, direction } = this.sort;

            // Pagination
            const from = (paginationPage * itemsPerPage) - itemsPerPage;
            const to = paginationPage * itemsPerPage;

            let tempArr = this.filterUsers
            
            if (this.selectedTeam) {
                tempArr = tempArr.filter(user => 
                    this.selectedTeam.clientId == user.item.userSettings.client
                );
            }

            if (sortBy?.length > 0) {
                if (sortBy === 'lastActive') {
                    tempArr.sort((a, b) => {
                        if (direction === 'asc') {
                            return new Date(a.item.userSettings?.lastLoginTime || 0) - new Date(b.item.userSettings?.lastLoginTime || 0)
                        } else {
                            return new Date(b.item.userSettings?.lastLoginTime || 0) - new Date(a.item.userSettings?.lastLoginTime || 0)
                        }
                    });
                } else if (sortBy === 'accountSettings') {
                    tempArr.sort((a, b) => {
                        if (direction === 'asc') {
                            return (a.item?.userSettings?.accountSettings || []).length - (b.item?.userSettings?.accountSettings || []).length
                        } else {
                            return (b.item?.userSettings?.accountSettings || []).length - (a.item?.userSettings?.accountSettings || []).length
                        }
                    });
                } else if (sortBy === 'team') {
                    tempArr.sort((a, b) => {
                        if (direction === 'asc') {
                            return this.getTeamClientName(a.item.uid).localeCompare(this.getTeamClientName(b.item.uid))
                        } else {
                            return this.getTeamClientName(b.item.uid).localeCompare(this.getTeamClientName(a.item.uid))
                        }
                    });
                } else if (sortBy === 'name') {
                    tempArr.sort((a, b) => {
                        if (direction === 'asc') {
                            return `${a.item.userSettings?.firstName} ${a.item.userSettings?.lastName}`.localeCompare(`${b.item.userSettings?.firstName} ${b.item.userSettings?.lastName}`)
                        } else {
                            return `${b.item.userSettings?.firstName} ${b.item.userSettings?.lastName}`.localeCompare(`${a.item.userSettings?.firstName} ${a.item.userSettings?.lastName}`)
                        }
                    });
                }
            }

            return tempArr.slice(from, to);
        },
        paginationPageCount() {
            const { itemsPerPage, filterUsers, filteredList, selectedTeam } = this;
            return Math.ceil((selectedTeam == null || selectedTeam === "" ? filterUsers.length : filteredList.length) / itemsPerPage)
        },
        filterUsers() {
                const teamValue = this.allUsers.map(user => TeamValueToLabel(user?.userSettings?.team));
                const rolesValue = this.allUsers.map(user => this.roleValuesToLabel(user?.userSettings?.permission));

                const usersWithTeamRoles = this.allUsers.map((user, index) => ({
                    ...user,
                    teamValue: teamValue[index],
                    rolesValue: rolesValue[index]
                }));

                const fuse = new Fuse(usersWithTeamRoles, {
                    keys: ['userSettings.firstName', 'userSettings.lastName', 'userSettings.email', 'userSettings.team', 'userSettings.retainer'],
                    threshold: 0.1,
                    distance: 100,
                    includeScore: true,
                    minMatchCharLength: 1
                });

                if (this.query && this.query.length > 0) {
                    return fuse.search(this.query || "");
                }

                return this.allUsers.map(val => ({
                    item: Object.assign(val, {}),
                    matches: [],
                    score: 0.2
                }));
            }
    },
    async mounted() {
        this.oauth = await getAuth();
        const db = tenantDatabase();
        const activeRetainerId = store.getters.getActiveRetainerState || 0;
        const userRef = ref(db, `/users`)
        const clientsRef = ref(db, `/clients`)

        onValue(clientsRef, (snapshot) => {
        if (snapshot.exists()) {
          const clientsObject = snapshot.val()
          this.firebaseClients = Object.keys(clientsObject).map(key => {
            return {
              ...clientsObject[key],
              clientId: key
            }
          });

          this.isLoading = false;
        } else {
          return {}
        }
      })
        onValue(userRef, snapshot => {
            let rawData = snapshot.val();
            if (rawData) {
                let formattedData = Object.keys(rawData).map((key) => {
                    return {
                        uid: key,
                        userSettings: rawData[key]
                    }
                });
                let arr = formattedData;
                if (!this.isClient) {
                    arr = formattedData.filter(user => {
                        return user.userSettings.permission !== 'admin' && user.userSettings.permission !== 'webcoda-user'
                    })
                }
                arr.forEach((item, index) => {
                    if (item.userSettings.permission === 'client-admin') {
                        arr.splice(index, 1);
                        arr.unshift(item);
                    }
                });
                this.allUsers = arr;
            }
            this.isLoading = false;
        });
        this.sort.sortBy = 'name';
		this.sort.direction = 'asc';
        this.checkForClientUsers
    },
    methods: {
        getRetainerValue(retainerId) {
            return ProjectValueToLabel(retainerId, this.oauth) || ''
        },
        handleFullReset() {
            this.clearSelection()
            this.query = ''
        },
        filterByTeam(team) {
            // Set the selected team
            this.selectedTeam = team;
            this.showModal = false;
        },
        clearSelection() {
            // Clear the selected team
            this.selectedTeam = '';
        },
        dropdownOptions(user) {
            if (user.userSettings && user.userSettings.team) {
                return TeamValueToLabel(user.userSettings.team, true);
            }
            return [];
        },
        showDetails() {
            this.showModal = !this.showModal;
        },
        close() {
            this.showModal = false;
        },
        handleSortBy(field) {
            const { direction, sortBy } = this.sort;
            this.sort.sortBy = field;
            if (sortBy === field) {
                this.sort.direction = direction === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort.direction = 'asc'
            }
        },
        handleSendEmailModalClose(success) {
            this.sendEmailModalActive = false
            if (success) {
                this.notifications = 'sendUserEmail'
            }
        },
        handlePaginationSelect(number) {
            this.paginationPage = number;
        },
        handlePaginationPrevNext(prev) {
            const { paginationPage } = this;
            this.paginationPage = prev ? paginationPage - 1 : paginationPage + 1;
        },
        roleValuesToLabel(value) {
            let role = allRoles.filter(item => {
                return item.value == value
            });
            return role[0] ? role[0].label : '';
        },
        handleEditUserLink(userObj) {
            const userString = JSON.stringify({ ...userObj });
            this.$router.push({
                name: 'Admin - User profile',
                params: {
                    data: userString
                }
            });
        },
        handleCopyEmail(email) {
            if (email) {
                navigator.clipboard.writeText(email).then(() => {
                    this.$notify({
                        title: "",
                        text: "Email address successfully copied to your clipboard",
                        duration: 5000,
                    });
                }, function (err) {
                    console.error('Async: Could not copy text: ', err);
                });
            }
        },
        convertDateString(ds, monthYear) {
            if (ds) {
                var options = monthYear ? { month: 'long', year: 'numeric' } : { month: 'short', day: 'numeric' };
                var date = new Date(ds);
                return date.toLocaleString('en-US', options)
            }
            return '—'
        },
        handleDeleteModalClose(success) {
            this.deleteModalActive = false;
            this.selectedUser = {}; 
            if (success) {
				this.notifications = 'deleteUser';
			}           
        },
        handleDeleteUserModal(e) {
            this.deleteModalActive = true;
            this.selectedUser.userId = e.uid;
            this.selectedUser.user = e.userSettings;
            document.body.classList.add('overflow-hidden');
        },
        async sendConfirmationMail(user) {

            this.oauth.onAuthStateChanged(currentUser => {
                if (currentUser) {

                    const currentToken = this.oauth.currentUser.accessToken;
                    const currentUrlBase = window.location.origin;

                    let data = {
                        Uid: user.uid,
                        SiteUrl: currentUrlBase
                    }

                    this.isLoading = true;

                    axios.post(`${this.$root.apiBaseUrl}/account/confirmation`, data, {
                        headers: {
                            'authorization': currentToken,
                            'timezone': this.$root.apiTimezone
                        }
                    }).then(res => {
                        this.isLoading = false;
                        // Uses kyvg/vue3-notification 
                        this.$notify({
                            title: "Success",
                            text: "A confirmation email has been sent to the registered email.",
                            duration: 5000,
                        });
                    }).catch(err => {
                        console.log(err)
                        this.isLoading = false;
                    });

                }
            });
        }
    }
}
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.u-content-tight {
    max-width: 562px;
    margin: 0 auto;
}

.box-shadow {
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}
</style>
