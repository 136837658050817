<script setup>
    import { getAuth } from "@/tenant";
    
    import sendUserEmail from "../helpers/SendUserEmail";

    import SearchIcon from '~icons/my-icons/search';
import IconTick from '~icons/my-icons/tick';

</script>

<template>
    <div v-if="modalActive" class="fixed top-0 left-0 w-full h-full z-[50] bg-black/50 flex items-center justify-center px-4">
        <div class="bg-white z-10 max-w-[840px] w-full relative rounded-lg">
            <h2 class="font-bold text-xl border-b border-bd border-solid px-8 py-6">Send an email</h2>
            <div class="p-6 sm:p-8">
                <div v-if="step === 1">
                    <div class="mb-6">
                        <div class="relative">
                            <SearchIcon class="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none" />
                            <input type="text" @input="filterUser = $event.target.value" :value="filterUser" placeholder="Filter users..." class="block rounded border border-bd w-full text-ellipsis px-12 py-2.5 h-12" />
                        </div>
                    </div>
                    <div class="grid sm:grid-cols-2 gap-4 mt-10">
                        <div v-for="user in filteredUserList" :key="user.uid" class="">
                            <div class="flex items-center">
                                <div class="text-left flex">
                                    <label class="text-sm relative inline-flex justify-center cursor-pointer">	
                                        <span>
                                            <span class="inline-flex mt-1 relative mr-3">
                                                <input class="peer sr-only" type="checkbox" @change="handleUserSelect(user)" :checked="selectedUsers.filter(a => a.uid === user.uid).length > 0">						
                                                <span class="inline-block w-5 h-5 border bg-white border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>	
                                                <span class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                                    <IconTick class="text-white"/>
                                                </span>
                                            </span>
                                        </span>		
                                        <span>
                                            <strong>{{user.userSettings.firstName}} {{user.userSettings.lastName}}</strong>
                                            <span class="block text-sm">{{user.userSettings.email}}</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center w-full text-base" v-if="filteredUserList.length === 0">
                        No users found.
                    </div>
                </div>
                <form ref="emailForm" v-if="step === 2">
                    <div class="flex items-center py-6 border-b border-grey-200 last:border-none">
                        <label class="flex items-center w-full">
                            <div class="w-32 pr-6 text-grey-300 text-xs font-bold">
                                Subject
                            </div>
                            <div class="text-grey-900 flex-1">
                                <div class="relative">
                                    <input type="text" required @input="evt => emailDetails.subject = evt.target.value" class="block rounded border border-bd w-full text-ellipsis px-3 py-2.5 h-12" />
                                </div>
                            </div>
                        </label>
                    </div> 
                    <div class="flex items-center py-6 border-b border-grey-200 last:border-none">
                        <label class="flex w-full">
                            <div class="mt-1 w-32 pr-6 text-grey-300 text-xs font-bold">
                                Recipients
                            </div>
                            <ul class="text-grey-900 flex-1">
                               <li class="text-sm" v-for="user in selectedUsers" :key="user.uid">
                                    <span class="font-medium">{{user.userSettings.firstName}} {{user.userSettings.lastName}}</span> ({{ user.userSettings.email }})
                                </li>
                            </ul>
                        </label>
                    </div> 
                    <div class="flex py-6 border-b border-grey-200 last:border-none">
                        <div class="w-32 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Message
                        </div>
                        <div class="text-grey-900 flex-1">
                            <textarea required @input="evt => emailDetails.message = evt.target.value" :value="reportMessage" class="w-full border border-bd rounded p-3 min-h-[180px]" placeholder="Add message"></textarea>
                        </div>
                    </div>  
                    <div class="flex py-6 border-b border-grey-200 last:border-none">
                        <div class="w-32 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Note
                        </div>
                        <div class="text-grey-900 flex-1">
                            <textarea required @input="evt => emailDetails.note = evt.target.value" :value="reportNote" class="w-full border border-bd rounded p-3 min-h-[180px]" placeholder="Add note"></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div class="px-8 py-4 flex items-center justify-between border-t border-bd border-solid">
                <div class="w-1/2">
                    <div class="text-base" v-if="selectedUsers.length > 0 && step === 1">
                        <div class="font-semibold">{{ selectedUsers.length }} selected user<span v-if="selectedUsers.length > 1">s</span></div>
                    </div>
                </div>
                <div>
                    <button class="mr-3 bg-grey-50 px-6 py-3 rounded-md" @click="goBack()">{{ step === 1 ? 'Cancel' : 'Back' }}</button>
                    <button v-if="step === 1" :disabled="selectedUsers.length === 0" class="transition bg-orange-500 px-6 py-3 rounded-md text-white disabled:text-grey-300 disabled:bg-grey-100" @click="step = 2">Continue</button>
                    <button v-else class="transition bg-orange-500 px-6 py-3 rounded-md text-white" @click="handleSendEmail()">Send email</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: ['allUsers', 'isActive'],
    emits: ['handleModalClose'],
    data() {
      return {
            allUsers: this.allUsers ? this.allUsers : [],
            selectedUsers: [],
            step: 1,
            filterUser: '',
            emailDetails: {
                subject: '',
                message: '',
                notes: ''
            },
            modalActive: false
        }
    },
    watch: {
        isActive: function(newVal, oldVal) {
            this.selectedUsers = [];
            this.modalActive = newVal;
        },
    },
    computed: {
        filteredUserList() {
            const { filterUser, allUsers } = this;
            return allUsers.map(user => ({...user.item})).filter(user => {
                return user.userSettings.firstName.toLowerCase().includes(filterUser.toLowerCase()) || 
                    user.userSettings.lastName.toLowerCase().includes(filterUser.toLowerCase()) ||
                    user.userSettings.email.toLowerCase().includes(filterUser.toLowerCase())
            });
        }
    },
    mounted() {
        
    },
    methods: {
        goBack() {
            const { step } = this;
            if(step === 2) {
                this.step = 1;
            } else {
                this.step = 1;
                this.modalActive = false;
                this.selectedUsers = [];
                this.$emit('handleModalClose');
            }
        },
        handleUserSelect(user) {
            const { selectedUsers } = this;
            if(selectedUsers.length === 0) {
                this.selectedUsers.push(user);
            } else {
                const existingUser = selectedUsers.filter(a => {
                    return a.uid === user.uid;
                }).length > 0;
                if(existingUser) {
                    this.selectedUsers = selectedUsers.filter(a => {
                        return a.uid !== user.uid;
                    });
                } else {
                    this.selectedUsers.push(user)
                }
            }
        },
        async handleSendEmail() {
            // Validation
            const form = this.$refs.emailForm;

            if(form.reportValidity()) {
                const data = {
                    message: this.emailDetails.message,
                    subject: this.emailDetails.subject,
                    note: this.emailDetails.note,
                    emails: this.selectedUsers.map(user => {
                        return {
                            email: user.userSettings.email,
                            name: `${user.userSettings.firstName} ${user.userSettings.lastName}"`,
                            firstname: user.userSettings.firstName,
                            lastname: user.userSettings.lastName
                        }
                    })
                };
                const auth = await getAuth();
                auth.onAuthStateChanged(currentUser => {
                    if(currentUser) {
                        sendUserEmail(data, auth.currentUser.accessToken).then(res => {
                            if(res) {
                                this.step = 1;
                                this.modalActive = false;
                                this.selectedUsers = [];
                                this.$emit('handleModalClose', true);

                                this.$notify({
                                    title: "",
                                    text: "Email sent successfully",
                                    duration: 5000,
                                });

                                this.filterUser = '';
                            } else {

                            }
                        });
                    }
                });
                
            }
            
        }
    }
  }
</script>

<style lang="scss">

</style>
