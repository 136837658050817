<script setup>

import { getAuth, tenantDatabase } from "@/tenant";
import { getDatabase, ref, update } from "firebase/database";
import axios from 'axios';
import store from '@/store';

import Container from '@/components/containers/Container.vue';
import Header from '@/components/objects/Header.vue';
import Notification from '@/components/objects/Notification.vue';
import Loading from '@/components/containers/Loading.vue';
import ArrowLeft from '~icons/my-icons/arrow-left';
import PermissionsValidation from '@/components/containers/PermissionsValidation.vue';

import EmailIcon from '~icons/my-icons/orange-email';
import LeaderboardIcon from '~icons/my-icons/orange-leaderboard';
import ServicesIcon from '~icons/my-icons/orange-services';

</script>

<template>
    <div class="">
        <PermissionsValidation :requiredRoles="['admin']">
            <div class="pt-12 pb-20 relative">
                <Container>
                    <h1 class="text-3xl font-bold mb-8">Settings</h1>
                    <div class="grid grid-cols-12 relative">
                        <div class="col-span-3 ">
                            <div class="flex flex-col gap-3 sticky top-6 bg-white">
                                <p class="font-bold text-sm py-2">Jump to</p>
                                <a href="#emails" class="text-purple-900 text-sm font-medium py-2">Emails</a>
                                <a href="#leaderboard" class="text-purple-900 text-sm font-medium py-2">Leaderboard</a>
                                <a href="#services" class="text-purple-900 text-sm font-medium py-2">Services</a>
                            </div>
                        </div>
                        <div class="col-span-7 flex flex-col gap-6">
                            <div
                                class=" border border-solid border-bd rounded-lg bg-white u-content-wrapped">
                                <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                                    <div id="emails" class="">
                                        <div class="flex px-6 py-4 gap-3 border-b border-bd">
                                            <EmailIcon class="" />
                                            <h2 class="text-sm font-bold">Emails</h2>
                                        </div>
                                        <div class="flex p-6">
                                            <label for="emailFromAddress" class="w-40 pr-6 text-xs font-bold mt-1">
                                                'Sent from' email address
                                            </label>
                                            <div class="text-grey-900 flex-1">
                                                <input :value="appSettings.emailFromAddress"
                                                    @input="appSettings.emailFromAddress = $event.target.value" type="text"
                                                    id="emailFromAddress"
                                                    class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                            </div>
                                        </div>
                                    </div>
                                </Loading>
                            </div>
                            <div
                                id="leaderboard"
                                class=" border border-solid border-bd rounded-lg bg-white u-content-wrapped">
                                <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                                    <div class="">
                                        <div class="flex px-6 py-4 gap-3 border-b border-bd">
                                            <LeaderboardIcon class="" />
                                            <h2 class="text-sm font-bold">Leaderboard</h2>
                                        </div>
                                        <div class="flex flex-col p-6 gap-4">
                                            <div class="flex">
                                                <label for="teamHoursTarget" class="w-40 pr-6 text-xs font-bold mt-1">
                                                    Team hours target (per day)
                                                </label>
                                                <div class="text-grey-900 flex-1">
                                                    <input :value="appSettings.teamHoursTarget"
                                                        @input="appSettings.teamHoursTarget = $event.target.value" type="text"
                                                        id="teamHoursTarget"
                                                        class="appearance-none flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                                </div>
                                            </div>
                                            <div class="flex ">
                                                <label for="teamHoursTarget" class="w-40 pr-6 text-xs font-bold mt-1">
                                                    User hours target (per day)
                                                </label>
                                                <div class="text-grey-900 flex-1">
                                                    <input :value="appSettings.userHoursTarget"
                                                        @input="appSettings.userHoursTarget = $event.target.value" type="text"
                                                        id="teamHoursTarget"
                                                        class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Loading>
                            </div>
                            <div
                                id="services"
                                class=" border border-solid border-bd rounded-lg bg-white u-content-wrapped">
                                <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                                    <div class="">
                                        <div class="flex px-6 py-4 gap-3 border-b border-bd">
                                            <ServicesIcon class="" />
                                            <h2 class="text-sm font-bold">Services</h2>
                                        </div>
                                        <div class="flex flex-col p-6 gap-4">
                                            <div class="pb-4 text-sm text-grey-500 border-b border-bd">
                                                Default services values
                                            </div>
                                            <div class="flex">
                                                <label for="retainer" class="w-40 pr-6 text-xs font-bold mt-1">
                                                    Retainer rates<br>(per hour)
                                                </label>
                                                <div class="text-grey-900 flex-1">
                                                    <input :value="appSettings.services.retainer"
                                                        @input="appSettings.services.retainer = $event.target.value" type="text"
                                                        id="retainer"
                                                        class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <label for="uat" class="w-40 pr-6 text-xs font-bold mt-1">
                                                    UAT
                                                </label>
                                                <div class="text-grey-900 flex-1">
                                                    <input :value="appSettings.services.uat"
                                                        @input="appSettings.services.uat = $event.target.value" type="text" id="uat"
                                                        class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <label for="hosting" class="w-40 pr-6 text-xs font-bold mt-1">
                                                    Hosting
                                                </label>
                                                <div class="text-grey-900 flex-1">
                                                    <input :value="appSettings.services.hosting"
                                                        @input="appSettings.services.hosting = $event.target.value" type="text"
                                                        id="hosting"
                                                        class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <label for="securityScan" class="w-40 pr-6 text-xs font-bold mt-1">
                                                    Security scan
                                                </label>
                                                <div class="text-grey-900 flex-1">
                                                    <input :value="appSettings.services.securityScan"
                                                        @input="appSettings.services.securityScan = $event.target.value" type="text"
                                                        id="securityScan"
                                                        class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <label for="siteAudit" class="w-40 pr-6 text-xs font-bold mt-1">
                                                    Site audit
                                                </label>
                                                <div class="text-grey-900 flex-1">
                                                    <input :value="appSettings.services.siteAudit"
                                                        @input="appSettings.services.siteAudit = $event.target.value" type="text"
                                                        id="siteAudit"
                                                        class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                                </div>
                                            </div>
                                            <div class="flex py-2">
                                                <label for="staging" class="w-40 pr-6 text-xs font-bold mt-1">
                                                    Staging
                                                </label>
                                                <div class="text-grey-900 flex-1">
                                                    <input :value="appSettings.services.staging"
                                                        @input="appSettings.services.staging = $event.target.value" type="text"
                                                        id="staging"
                                                        class="flex-1 w-full border border-bd border-solid h-12 rounded px-4" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Loading>
                            </div>
                        </div>
                    </div>
                    <div
                        class="mt-8 sticky bottom-0 border-t border-solid border-bd rounded-lg bg-white u-content-wrapped">
                        <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                            <div class="flex items-center justify-end py-6 border-b border-grey-200 last:border-none">
                                <button class="text-blue-500 mr-6" @click="$router.go()">
                                    Cancel
                                </button>
                                <button
                                    class="ml-3 bg-orange-500 rounded text-white py-2.5 px-5 inline-flex items-center disabled:text-grey-300 disabled:bg-grey-100"
                                    @click="saveSettings()">
                                    Save
                                </button>
                            </div>
                        </Loading>
                    </div>
                </Container>
            </div>
        </PermissionsValidation>
    </div>
</template>

<script>

export default {
    props: [],
    data() {
        return {
            appSettings: {
                teamHoursTarget: 0,
                userHoursTarget: 0,
                emailFromAddress: "",
                services: {},
                integration: {}
            },
            isSubmitting: false
        }
    },
    mounted() {
        if (store.getters.getAppSettings) {
            this.appSettings = { ...store.getters.getAppSettings }
            if (!this.appSettings.services) {
                this.appSettings.services = {
                    retainer: 180,
                    uat: 250,
                    hosting: 200,
                    securityScan: 800,
                    siteAudit: 280,
                    staging: 480
                }
            }
        }
    },
    computed: {

    },
    methods: {
        saveSettings() {

            this.isSubmitting = true;

            const { teamHoursTarget, userHoursTarget, emailFromAddress, services, integration } = this.appSettings;
            const db = tenantDatabase();
            const updates = {};

            updates[`/appSettings`] = { teamHoursTarget, userHoursTarget, emailFromAddress, services, integration };
            update(ref(db), updates).then(res => {
                store.commit('updateAppSettings', { teamHoursTarget, userHoursTarget, emailFromAddress, services, integration });
                this.$notify({
                    title: "",
                    text: "Setting updated successfully",
                    duration: 5000,
                });
                this.isSubmitting = false;
            }).catch(err => {
                console.log(err);
                this.isSubmitting = false;
            });
        }
    }
}
</script>

<style lang="scss"></style>
