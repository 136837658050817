<script setup>

import { getAuth, tenantDatabase } from "@/tenant";

	import { getDatabase, ref, get, query, onValue, orderByChild, equalTo } from "firebase/database";
	import axios from 'axios';

	import store from '@/store';
	import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
	import Loading from '@/components/containers/Loading.vue';
	import TeamValueToLabel from '@/helpers/TeamValueToLabel';
	import ProjectValueToLabel from '@/helpers/ProjectValueToLabel';
	import DashboardUserDropdown from '@/components/objects/DashboardUserDropdown.vue'
	
	import PermissionsHelper from '@/helpers/PermissionHelpers';
import {authTokenUpdate} from '@/helpers/AuthTokenUpdate';

import { Skeletor } from "vue-skeletor";
	
	import Tile from '@/components/objects/Tile.vue'
	
	import Service from '~icons/my-icons/service.svg'
	import Time from '~icons/my-icons/time.svg'
	
</script>

<template>
	<Loading :isLoading="snapshotLoading">
		<div class="grid xl:grid-cols-3 mb-12 gap-8">
					<div class="xl:col-span-2">
						
						<!--<div class="flex flex-col flex-wrap rounded-lg">
							<div class="mt-12 mb-8 flex justify-between flex-wrap sm:space-y-4 lg:space-y-0">
								<div class="flex lg:col-6 sm:col-12 lg:pr-4">
									<router-link :to="{ name: 'Beta Dashboard Services', params: { retainerId: $route.params.retainerId }}" class="p-6 flex items-center w-full bg-white justify-between border border-gray-200 rounded-lg">
										<div class="flex flex-col">
											<p class="text-2xl font-medium">1</p>
											<p class="text-xs font-bold">Active Services</p>
										</div>
										<div>
											<Service class="w-[64px] h-[64px]"></Service>
										</div>
									</router-link>
								</div>
								<div class="flex lg:col-6 sm:col-12 lg:pl-4">
									<router-link :to="{ name: 'Beta Dashboard Details', params: { retainerId: $route.params.retainerId }}" class="p-6 flex items-center w-full bg-white justify-between border border-gray-200 rounded-lg">
										<div class="flex flex-col">
											<p class="text-2xl font-medium">12</p>
											<p class="text-xs font-bold">Days since last login</p>
										</div>
										<div>
											<Time class="w-[64px] h-[64px] text-white"></Time>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					-->
						<div class="border border-gray-200 mt-12 rounded-lg ">
							<div class="border-b border-gray-200 w-full px-6 py-4 flex justify-between items-center">
								<p class="text-sm font-bold">Retainer report</p>
								<router-link class="border bg-grey-50 border-grey-200 rounded px-3 py-1.5 text-xs " :to="{ name: 'Beta Dashboard Reports', params: { retainerId: $route.params.retainerId }}">View all reports</router-link>
							</div>
							<div class="flex flex-wrap">
								<div class="lg:col-6 sm:col-12 px-6 pb-6 bg-gray-50">
									<p class="text-sm font-bold text-center pt-6 pb-4">Latest report</p>
										<div v-if="allTileData.length > 0">
												<div :key="tile.listData" v-for="(tile, index) in allTileData">
														<Tile class="bg-brand-primary text-white !px-4 py-[6px]" :retainerId="$route.params.retainerId" v-if="index < initItemCount" :data="tile" />
												</div>
										</div>
										<Skeletor v-else height="320px" />
								</div>
								<div class="lg:col-6 sm:col-12 p-6 flex flex-col">
									<div class="text-sm border-b border-gray-200 pb-2 last-of-type:mb-0 grid grid-cols-2 gap-4"><span class="font-bold">Contract start</span>{{retainerData?.startDate}}</div>
									<div class="text-sm border-b border-gray-200 py-2  last-of-type:mb-0 grid grid-cols-2 gap-4"><span class="font-bold">Contract renewal</span> {{retainerData?.contractExpiry}}</div>
									<div class="text-sm border-b border-gray-200 py-2  last-of-type:mb-0 grid grid-cols-2 gap-4" v-if="retainerData?.showRetainerServices"><span>Retainer rate</span> {{retainerData?.services?.retainer.cost ? `A$${retainerData?.services?.retainer.cost} per hour` : ''}}</div>
									<div class="text-sm border-b border-gray-200 py-2  last-of-type:mb-0 grid grid-cols-2 gap-4"><span class="font-bold">Monthly hours</span> {{retainerData?.monthlyHours ? retainerData?.monthlyHours : retainerData?.services?.retainer.hours }}</div>
									<div class="text-sm border-b border-gray-200 py-2  last-of-type:mb-0 grid grid-cols-2 gap-4"><span class="font-bold">Monthly value</span> {{retainerData?.services?.retainerRate && retainerData?.monthlyHours ? `A$${retainerData?.services?.retainerRate * retainerData?.monthlyHours} per month` : retainerData?.value }}</div>
								</div>
							</div>
						</div>
						<!-- <div class="mt-8 border border-gray-200 rounded-lg">
						<div class="border-b border-gray-200 w-full px-6 py-4 flex justify-between items-center">
							<p class="text-sm font-bold">Your account manager<span v-if="internalUsers.length > 1">s</span></p>
						</div>
						<div class="flex flex-wrap px-6 ">
							<div class="flex flex-wrap border-b border-gray-200 -mt-px w-full">
								<div class="flex lg:col-6 pr-6 items-center justify-between text-sm py-4 border-b border-gray-200  w-full last-of-type:mb-0 translate-y-px" :key="user.firstName" v-for="(user, i) in internalUsers">
									<div class="flex items-center">
										<img class="h-12 w-12 rounded-full object-cover" :src="user.profileImage" alt="" />
									<div class="pl-4">
										<p class="text-xs text-gray-900">{{user.userSettings.firstName}} {{user.userSettings.lastName}}{{i == internalUsers.length - 1 ? '' : ', '}}</p>
										<p class="text-gray-400 text-xs">{{ user.email }}</p>
									</div>
								</div>
								<DashboardUserDropdown 
									:userData="user"
									@handleCopyEmail="handleCopyEmail(user.userSettings?.email)"
								/>
								</div>
							</div>
						</div>
						<p class="px-6 pt-4 pb-6 text-sm font-medium">For urgent enquiries, please give us a call on <a href="tel:0293703636" class="text-link underline">02 9370 3636</a></p>
					</div> -->
				</div>
				<div class="px-0 h-auto bg-brand-secondary rounded-lg mt-12 text-sm font-bold text-white">
					<h2 class="px-6 py-4 border-b border-purple-400 ">What’s new at Webcoda</h2>
					<div class="px-6">
						<div class="pt-6 pb-4 border-b border-purple-400 ">
							<p class="pb-4">Recent projects</p>
							<a href="https://webcoda.com.au/projects/infrastructure-nsw" target="_blank">
								<img class="rounded-md w-full h-[367px] object-cover" src="@/assets/img/insw.webp" alt="">
								<p class="text-lg pt-4">Flexible website for Government agency, Infrastructure NSW</p>
								<p class="text-xs font-normal">infrastructure.nsw.gov.au</p>
							</a>
						</div>
						<div class="py-6">
							<p class="pb-4">Latest Blog</p>
							<a href="https://webcoda.com.au/blogs/migrating-your-website-to-hubspot-boost-your-marketing-performance" target="_blank">
								<img class="rounded-md w-full h-[177px] object-cover" src="@/assets/img/hubspot-blog.webp" alt="">
								<p class="text-lg pt-4">Migrating Your Website to HubSpot: Boost Your Marketing Performance. pt 1</p>
							</a>
						</div>
					</div>
				</div>
		</div>
	</Loading>
</template>

<script>
	export default {
		props: ['title', 'tileData'],
		data() {
			return {
				retainerData: null,
				snapshotLoading: true,
				clientData: {},
				retainerChangelogs: null,
				tileData: [],
				parsedData: [],
				internalUsers: {},
        initItemCount: 1,
			}
		},
		watch: {
			'$route' (to, from) {
				if(to?.params?.retainerId) {
					this.handleUpdateActiveRetainer(to?.params?.retainerId)
					this.getStatistics()
				} 
			},
			tileData: function(newVal, oldVal) {
					this.parsedData = newVal;
			}
		},
		computed: {
			formatRetainerUrls() {
				if(this.retainerData?.url) {
					const urlArr = this.retainerData?.url?.replace(/\r?\n|\r/gm, '').split(',');
					return urlArr.filter(n => /\S/.test(n));
				}
				return []
			},
			filterSnapshotMonths() {
				let { tileData } = this;
				// Filter out by tasks count
				return tileData.filter(item => item.listData.filter(l => l.itemName === 'Tasks')[0].itemValue > 0);
			},
			
			mostCurrentApprovedMonth() {
				const filteredData = this.filterSnapshotMonths;
				if(filteredData && filteredData.length > 0) {
					filteredData.sort((a, b) => {
						return new Date(b.title) - new Date(a.title) 
					});
					return filteredData[0];
				}
				return '';
			},
			allTileData() {
				return this.tileData.sort((a,b) => {
						return new Date(b.title) - new Date(a.title)
				});
			}
		},
		async created() {
			this.currentUser = await authTokenUpdate();
		},
		async mounted() {

			await initCurrentUserSession(this);

			this.getStatistics();

			this.getRetainerData();
			this.getRetainerChangelogs();

		},
		methods: {
			getRetainerChangelogs() {
				const userRetainer = this.$route.params.retainerId;
				const db = tenantDatabase();
				const retainerHistoryRef = ref(db, `/retainerHistory/${userRetainer}`);
				onValue(retainerHistoryRef, snapshot => {
					if(snapshot.val()) {
						const res = snapshot.val();
						const arr = [];
						for(const month in res) {
							var obj = {
								title: this.dateLabel(`${month}-1`)
							};
							var changesArr = [];
							for(const change in res[month]) {
								changesArr.push(res[month][change])
							}
							obj.changes = changesArr;
							arr.push(obj)
						}
						this.retainerChangelogs = arr;
					}
				});
			},
			getRetainerData() {
				
				const userRetainer = this.$route.params.retainerId;
				
				const db = tenantDatabase();
				const retainersRef = ref(db, `/retainers/${userRetainer}`);
				const clientRef = ref(db, `/clients/${this.$route.params.clientId}/`);
				// const accountManagersRef = query(ref(db, 'users'), orderByChild(`responsibilities/${this.$route.params.clientId}`), equalTo(true))

				// get(accountManagersRef).then(data => {
				// 	const users = data.val();

				// 	console.log("users")
				// 	console.log(users)

				// 	this.internalUsers = users;
				// });

				onValue(retainersRef, snapshot => {
					this.retainerData = snapshot.val() ? snapshot.val() : {};
					if (this.retainerData.plans != null && this.retainerData.plans.length > 0) {
						var lastPlan = this.retainerData.plans[this.retainerData.plans.length - 1];
						this.retainerData.monthlyHours = lastPlan.monthlyHours;
						this.retainerData.rolloverHours = lastPlan.rolloverHours;
						this.retainerData.rolloverFrom = lastPlan.rolloverFrom;
					}
				});
				
				onValue(clientRef, snapshot => {
					if(snapshot.val()) {

						const data = snapshot.val();

						this.clientData = data;
					}
				});
				this.snapshotLoading = false;
			},
			handleUpdateActiveRetainer(retainerId) {
				this.snapshotLoading = true;

				this.getRetainerData();
				this.getRetainerChangelogs();
			},
			dateLabel(dateStr) {
				var options = { month: 'long', year: 'numeric' };	
				var thisDate = new Date(dateStr);
				return thisDate.toLocaleString('en-GB', options);
			},
			async getStatistics(){
				var filter = [];
				var today = new Date();
				var d;
				
				for(var i = 11; i >= 0; i -= 1) {
					d = new Date(today.getFullYear(), today.getMonth() - i, 1);
					filter.push({
						month: d.getMonth() + 1,
						year: d.getFullYear()
					});
				}

				
				const auth = await getAuth();
				auth.onAuthStateChanged(user => {
					if(user) {
						const currentToken = auth.currentUser.accessToken;					

						axios({
							method: 'post',
							url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
							data: filter,
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							},	
						}).then(res => {
							this.tileData = res.data;	
							this.snapshotLoading = false;				

							if(!PermissionsHelper.isWebcodaAdmin()) {
								this.tileData = res.data
									.filter((item) => item.listData.find(lineItem => lineItem.itemName == "Billable").itemValue !== '0hrs' || item.listData.find(lineItem => lineItem.itemName == "UnBillable").itemValue !== '0hrs')
							}

						}).catch(err => {
							console.log(err);
						});

					}});
				
			},
			showAllResults() {
					this.initItemCount = this.allTileData.length
			},
			
			handleCopyEmail(email) {
				if(email) {
					navigator.clipboard.writeText(email).then(() => {
					this.notifications = 'copyEmail';
					setTimeout(() => {
						this.notifications = '';
					}, 3600)
					}, function(err) {
							console.error('Async: Could not copy text: ', err);
					});
				}
			},
}
	}
</script>
