import { getAuth, tenantDatabase } from "../tenant";
import { getDatabase, ref, child, get } from "firebase/database";
import { initializeApp } from "firebase/app";
import getFirebaseConfig from "../helpers/GetFirebaseConfig";

import store from "../store"

export default async function initCurrentUserSession(context) {

    function getUserDetails() {
        return new Promise(async resolve => {
            const auth = await getAuth();
            auth.onAuthStateChanged(user => {
                if(user) {
                    auth.currentUser.getIdTokenResult().then(tokenResult => {
                        const userId = tokenResult.claims.user_id;
                        const dbRef = ref(tenantDatabase());
                        return get(child(dbRef, `users/${userId}`)).then(snapshot => {
                            if (snapshot.exists()) {
                                const userObj = snapshot.val();
                                store.commit('updateUserState', userObj);
                                resolve(snapshot.val());
                            } else {
                                console.log("No data available");
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    });
                } else {
                    context.$router.push("/login");
                }
            });
        });
    } 

    function getAppSettings() {
        
        return new Promise(resolve => {
            const db = tenantDatabase();
            const dbRef = ref(db);
            return get(child(dbRef, `appSettings`)).then(snapshot => {
                if (snapshot.exists()) {
                    store.commit('updateAppSettings', snapshot.val());
                }
                resolve('done');
            });
        });
    }

    return getUserDetails().then(res => {
        return getAppSettings().then(resA =>{
            return res
        });
    });

}
