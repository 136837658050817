<script setup>

  import store from "../../store";
  import { getAuth } from "../../tenant";
  import Loading from "../containers/Loading.vue";
  import Container from '../containers/Container.vue';

</script>

<template>
    <template v-if="allowRender">
        <div :class="[!allowRender ? '' : '']">
            <span v-if="disableSpinner">
                <slot v-if="allowRender"></slot>
            </span>
            <Loading 
                v-else
                :isLoading="isLoading"
            >
                <slot v-if="allowRender"></slot>
                <Container v-else-if="noMessage == undefined">
                    <div class="text-center" v-if="noProjectsAssigned">
                        Oh no! It seems there isn't a project assigned to your account. <br>
                        Please contact a Webcoda administrator to get this resolved.
                    </div>
                    <div class="text-center" v-else>
                        You do not have sufficient permissions to view this resource.
                    </div>
                </Container>
            </Loading>
        </div>
    </template>
</template>

<script>
    export default {
        data() {
            return {
                allowRender: false,
                isLoading: true,
                noProjectsAssigned: false,
                disableSpinner: this.hideSpinner ? this.hideSpinner : false 
            }
        },
        props: ['requiredRoles', 'noMessage', 'hideSpinner', 'hideAsAdmin'],
        async beforeMount() {
            (await getAuth()).onAuthStateChanged(async userAuth => {
                if(userAuth) {
                    const { currentUser } = await getAuth();
                    if(!currentUser.emailVerified) {
                        // this.$router.push({path: '/user-setup'});
                        this.isLoading = false;
                    } else {
                        const viewAsClient = store.getters.getViewAsClient;
                        const currentUserState = store.getters.getUserState;
                        if(this.requiredRoles) {
                            let matchRole;
                            if(viewAsClient === true) {
                                matchRole = this.requiredRoles.filter(role => {
                                return role == 'client-user';
                            });
                            } else {
                                matchRole = this.requiredRoles.filter(role => {
                                    return role == currentUserState.permission;
                                });
                            }
                            if(matchRole.length > 0) {
                                this.allowRender = true;
                            }
                        } else {
                            this.allowRender = true;
                        }
                        this.isLoading = false;
                    }
                } else {
                    this.$router.push({path: '/login'})
                    this.isLoading = false;
                }
            });
        },
        mounted() {
            
        }
    }
</script>
