<script setup>

import { getAuth, tenantDatabase } from "@/tenant";
    import { getDatabase, ref, update } from "firebase/database";
    import axios from 'axios';
    import store from '@/store';

    import Container from '@/components/containers/Container.vue';
    import Notification from '@/components/objects/Notification.vue';
    import Loading from '@/components/containers/Loading.vue';
    import ArrowLeft from '~icons/my-icons/arrow-left';
    import PermissionsValidation from '@/components/containers/PermissionsValidation.vue';

</script>

<template>
    <div class="ml-[196px] scroll-smooth">
        <PermissionsValidation :requiredRoles="['admin']">
            <div class="px-12 bg-white border-b border-solid border-bd">
                <Container>
                    <div class="w-full flex text-sm font-bold">
                        <router-link 
                            class="px-10 py-5 bg-white block"
                            active-class="border-b-[3px] border-brand-primary parent-active"
                            :to="{ name: 'Admin Users' }"
                        >
                            Users
                        </router-link>
                        <router-link 
                            class="px-10 py-5 bg-white block"
                            active-class="border-b-[3px] border-brand-primary parent-active"
                            :to="{ name: 'Application Settings' }"
                        >
                            Settings
                        </router-link>
                        <router-link 
                            class="px-10 py-5 bg-white block"
                            active-class="border-b-[3px] border-brand-primary parent-active"
                            :to="{ name: 'Audit Logs' }"
                        >
                            Audit Logs
                        </router-link>
                    </div>
                </Container>
            </div>
            <router-view></router-view>
        </PermissionsValidation>
    </div>
</template>

<script>

  export default {
    props: [],
    data() {
      return {
          appSettings: {
              teamHoursTarget: 0,
              userHoursTarget: 0,
              emailFromAddress: "",
              services: {}
          },
          notification: "",
          isSubmitting: false
      }
    },
    mounted() { 
        if(store.getters.getAppSettings) {
            this.appSettings = {...store.getters.getAppSettings}
            if(!this.appSettings.services) {
                this.appSettings.services = {
                  retainer: 180,
                  uat: 250,
                  hosting: 200,
                  securityScan: 800,
                  siteAudit: 280,
                  staging: 480
              }
            }
        }
    },
    computed: {
        
    },
    methods: {
        saveSettings() {

            this.isSubmitting = true;
            this.notification = "";

            const { teamHoursTarget, userHoursTarget, emailFromAddress, services } = this.appSettings;
            const db = tenantDatabase();
            const updates = {};

            updates[`/appSettings`] = { teamHoursTarget, userHoursTarget, emailFromAddress, services };
            update(ref(db), updates).then(res => {
                store.commit('updateAppSettings', { teamHoursTarget, userHoursTarget, emailFromAddress, services });
                this.notification = "Success";
                this.isSubmitting = false;
            }).catch(err => {
                console.log(err);
                this.isSubmitting = false;
            });
        }
    }
  }
</script>

<style lang="scss">
    
</style>
