import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import {getDatabase,ref,get,onValue } from "firebase/database";

var currentSource = {};
var currentApp = null;
var currentdb = null;


async function getAuthOverride() {
    var app = getAuth()
    app.tenantId = (await getCurrentSource()).tenantId;
    return app;
}


async function getCurrentTeanantID(){
    return (await getCurrentSource()).tenantId; 
}

async function getCurrentDbName(){
    return (await getCurrentSource()).db; 
}

function getTenantIdMapping(reference) {

    var config = getConfigurationApp();
    const database = getDatabase(config);

    return new Promise((resolve, reject) => {
        const dbRef = ref(database, reference);

        onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                resolve(snapshot.val());
            } else {
                reject(new Error('No data available'));
            }
        }, (error) => {
            reject(error);
        }, { onlyOnce: true });
    });
}

async function getCurrentSource(){
    const domain = window.location.hostname;

    var code = "";
    domain.split('.').forEach(function (x) {
        if (!!code) {
            code += "_";
        }
        code += x;
    });

    var tenantId = await getTenantIdMapping(`domains/${code}/tenantId`);

    if (tenantId) {
        return {
            domain: domain,
            tenantId: tenantId,
            db: `https://clientdash-${tenantId}.asia-southeast1.firebasedatabase.app`
        }
    }

    var select = Object.values(sources).find(obj => obj.domain === domain);

    if (select){
        return select;        
    }    

    return sources.default;
}


function getCurrentDb(){
    return currentdb;
  }

  function getCurrentApp(){
    return currentApp;
  }


const tenantAppsCache = {};

function getAppForTenant(tenantId) {
    if (!tenantAppsCache[tenantId]) {
        tenantAppsCache[tenantId] = initializeApp({
            serviceAccountId: 'firebase-adminsdk-97415@clientdash-prod.iam.gserviceaccount.com',
            projectId: "clientdash-prod",
            databaseURL: `https://clientdash-${tenantId}.asia-southeast1.firebasedatabase.app`,
            tenantId: tenantId
        }, tenantId);
    }
    return tenantAppsCache[tenantId];    
}

function getConfigurationApp() {
    if (!tenantAppsCache['global']) {
        tenantAppsCache['global'] = initializeApp({
            serviceAccountId: 'firebase-adminsdk-97415@clientdash-prod.iam.gserviceaccount.com',
            projectId: "clientdash-prod",
            databaseURL: `https://clientdash-prod-tenants.asia-southeast1.firebasedatabase.app`,            
        }, 'global');
    }
    return tenantAppsCache['global'];    
}


async function tenantMiddleware(req, res, next) {    
    currentSource = await getCurrentSource();

    const firebaseApp = getAppForTenant(currentSource.tenantId);

    currentApp = firebaseApp;
    currentdb = getDatabase(firebaseApp);
  }


export { getAuthOverride as getAuth, getCurrentTeanantID, getCurrentDb as tenantDatabase, getCurrentApp as tenantApp, getCurrentDbName, tenantMiddleware };

