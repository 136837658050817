<script setup>

import { getAuth, tenantDatabase } from "@/tenant";
import { sendSignInLinkToEmail, signOut } from "firebase/auth";
import { getDatabase, ref, get, child, onValue } from "firebase/database";
import getFirebaseConfig from "../../helpers/GetFirebaseConfig";
import axios from "axios";

import store from "../../store";
import allRoles from "../../data/alluserRoles";

import Container from '../containers/Container.vue';
import Loading from '../containers/Loading.vue';
import UserDropdown from '../objects/UserDropdown.vue';
import ChevronLeft from '~icons/my-icons/chevron-left';
import ChevronRight from '~icons/my-icons/chevron-right';
import DeleteUserConfirmation from '../DeleteUserConfirm.vue';
import Notification from '../objects/Notification.vue';
import UserAvatar from '../objects/UserAvatar.vue';
import SendEmailModal from '../SendEmailModal.vue';

import TeamValueToLabel from '../../helpers/TeamValueToLabel';
import ProjectValueToLabel from '../../helpers/ProjectValueToLabel';
import PermissionHelpers from '../../helpers/PermissionHelpers';

import SearchIcon from '~icons/my-icons/search';
import UserPlusIcon from '~icons/my-icons/user-plus';
import PlusClose from '~icons/my-icons/cross';
import IconArrowDown from '~icons/my-icons/sort-down';
import CopyIcon from '~icons/my-icons/copy';
import Fuse from 'fuse.js';

</script>

<template>
    <div class="">
        <div class="py-12 bg-white ">
            <Container class="w-full flex justify-between items-center">
                <h1 class="text-3xl font-bold">Users</h1>
                <div class="flex relative gap-3">
                    <div class="relative">
                        <SearchIcon class="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none" />
                        <input type="text" :value="query" @input="query = $event.target.value"
                            class="block rounded border border-bd w-[300px] text-ellipsis px-12 py-2.5 h-12"
                            placeholder="Search users" />
                        <button v-if="query.length > 0" @click="() => query = ''"
                            class="absolute top-1/2 right-4 -translate-y-1/2">
                            <PlusClose class="w-3" />
                            <span class="sr-only">Clear search</span>
                        </button>
                    </div>
                    <router-link v-if="PermissionHelpers.CanAddUsers()" :to="{ name: 'Admin Users - Add new contact' }"
                        class="relative flex items-center bg-orange-500 rounded text-white py-2.5 px-5">
                        <UserPlusIcon class="pointer-events-none w-5 h-5 mr-2 text-[0.6rem]" />
                        <span>Add new user</span>
                    </router-link>
                </div>
            </Container>
        </div>
        <Container>
            <div class="">
                <Notification :type="'relative'" :notificationType="notifications" />
                <div class="rounded-lg border border-solid mb-8">
                    <Loading :isLoading="isLoading" type="overlay">
                        <table class="w-full">
                            <thead class="border-none">
                                <tr class="bg-gray-50 border-b border-gray-200">
                                    <th class="rounded-tl-lg">
                                        <button
                                            class="block px-8 py-4 text-left text-purple-900 text-xs font-semibold"
                                            @click="handleSortBy('name')">
                                            Name
                                            <span v-if="sort.sortBy === 'name'" class="text-[10px] inline-block ml-2"
                                                :class="{ 'rotate-180': sort.direction === 'desc' }">
                                                <IconArrowDown />
                                            </span>
                                        </button>
                                    </th>
                                    <th class="">
                                        <button
                                            class="w-[108px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold"
                                            @click="handleSortBy('lastActive')">Last active
                                            <span v-if="sort.sortBy === 'lastActive'" class="text-[10px] inline-block ml-2"
                                                :class="{ 'rotate-180': sort.direction === 'desc' }">
                                                <IconArrowDown />
                                            </span></button>
                                    </th>
                                    <th class="w-[96px]  rounded-tr-lg">
                                        <!-- Options -->
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="filteredList.length > 0" class="">
                                <tr v-for="user in filteredList" :key="user.item.userSettings.email"
                                    class="border-b border-solid last:border-none group">
                                    <td class="bg-white pl-4 pr-2 py-3 text-xs group-last:rounded-bl-lg w-[600px]">
                                        <div class="inline-flex items-center">
                                            <router-link class="inline-flex items-center"
                                                :to="{ name: 'Admin Users - User profile', params: { userid: user.item.uid } }">
                                                <span
                                                    class="mr-4 rounded-full overflow-hidden flex-shrink-0 min-w-[64px] min-h-[64px] inline-flex items-center justify-center bg-center bg-cover bg-black"
                                                    :style="{ backgroundImage: `url(${user.item.userSettings.profileImage})` }">
                                                    <UserAvatar v-if="!user.item.userSettings.profileImage"
                                                        :name="`${user.item.userSettings?.firstName} ${user.item.userSettings?.lastName}`" />
                                                </span>
                                            </router-link>
                                            <div>
                                                <router-link class="inline-flex items-center"
                                                    :to="{ name: 'Admin Users - User profile', params: { userid: user.item.uid } }">
                                                    <span class="mb-1 text-grey-900 text-xs block">{{
                                                        user.item.userSettings?.firstName }}
                                                        {{ user.item.userSettings?.lastName }}</span>
                                                </router-link>
                                                <span class="flex items-center text-grey-400 text-xs group cursor-pointer"
                                                    @click="handleCopyEmail(user.item.userSettings?.email)">{{
                                                        user.item.userSettings?.email }}
                                                    <CopyIcon
                                                        class="ml-2 opacity-0 hover:text-blue-500 text-grey-900 group-hover:opacity-100">
                                                    </CopyIcon>
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td :class="!user.item.userSettings?.lastLoginTime ? 'text-gray-400' : ''"
                                        class="bg-white px-2 py-3 text-xs">
                                        <div v-if="user.item.userSettings?.lastLoginTime" class="grid grid-cols-1">
                                            <p>{{ moment(user.item.userSettings?.lastLoginTime).format('ddd, DD MMM yyyy') }}</p>
                                            <p>{{ moment(user.item.userSettings?.lastLoginTime).format('HH:mm') }} AEST</p>
                                        </div>
                                        <div v-else>
                                            'Registration pending' 
                                        </div>
                                    </td>
                                    <td class="bg-white py-3 text-xs text-right group-last:rounded-br-lg">
                                        <div class="inline-flex items-center px-4">
                                            <UserDropdown type="adminUsers" value="view" :userData="user.item"
                                                @handleCopyEmail="handleCopyEmail(user.item.userSettings?.email)"
                                                @handleDeleteModal="handleDeleteUserModal"
                                                @handleResendConfirmation="sendConfirmationMail(user)" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <!-- <tbody v-else-if="filteredList.length === 0">
                                <tr class="border-b border-solid last:border-none">
                                    <td class="bg-white py-8  gap-3 text-xs text-center" colspan="6">
                                        <p class="pb-3">No users found</p>
                                        <button @click="handleFullReset" class="inline-flex border text-xs border-gray-200 items-center py-1.5 px-4 rounded bg-gray-50 cursor-pointer">
                                            Reset filters
                                        </button>
                                    </td>
                                </tr>
                            </tbody> -->
                        </table>
                    </Loading>
                    <div v-if="filteredList.length == 0" class="items-center justify-center">
                        <div class="px-12 pt-6 pb-4 text-center font-medium">
                            No users found.
                        </div>
                        <div v-if="query" class="px-12 mb-6 text-center font-medium">
                            <button class="border rounded-md border-gray-200 bg-gray-50 p-1.5 px-4" @click="handleFullReset()"> Reset filters</button>
                        </div>
                    </div>
                </div>
                <div v-if="paginationPageCount > 1" class="flex items-center justify-between">
                    <button class="mr-3 disabled:text-grey-300 text-sm bg-gray-50" :disabled="paginationPage == 1"
                        @click="handlePaginationPrevNext(true)">
                        <span class="sr-only">Previous page</span>
                        <div class="flex items-center justify-center rounded border border-gray-200 py-2 px-5">
                            <ChevronLeft />
                            <p class="ml-2">Prev</p>
                        </div>
                    </button>
                    <div>
                        <button class="mx-2"
                            :class="{ 'font-bold w-10 h-10 bg-black rounded-full text-white': index === paginationPage }"
                            v-for="index in paginationPageCount" :key="index" @click="handlePaginationSelect(index)">
                            {{ index }}
                        </button>
                    </div>
                    <button class="ml-3 disabled:text-grey-300 text-sm bg-gray-50"
                        :disabled="paginationPage == paginationPageCount" @click="handlePaginationPrevNext(false)">
                        <span class="sr-only">Next page</span>
                        <div class="flex items-center justify-center rounded border border-gray-200 px-5 py-2">
                            <p class="pr-2">Next</p>
                            <ChevronRight />
                        </div>
                    </button>
                </div>
            </div>
        </Container>
        <SendEmailModal @handleModalClose="handleSendEmailModalClose" :isActive="sendEmailModalActive"
            :allUsers="filteredList" />
        <DeleteUserConfirmation :isActive="deleteModalActive" :selectedUser="selectedUser"
            @handleModalClose="handleDeleteModalClose" admin="true" />
    </div>
    <router-view></router-view>
</template>

<script>
import { initializeApp } from "firebase/app";
import teamValueToLabel from "../../helpers/TeamValueToLabel";
import moment from 'moment'
export default {
    data() {
        return {
            query: this.propsQuery ? this.propsQuery : '',
            allUsers: [],
            sort: [],
            allProjectsList: [],
            currentUser: null,
            firebaseApp: null,
            isLoading: true,
            deleteModalActive: false,
            selectedUser: {},
            notifications: this.notification ? this.notification : '',
            paginationPage: 1,
            itemsPerPage: 10,
            sendEmailModalActive: false,
            showModal: false,
            selectedTeam: null,
        }
    },
    props: ['notification', 'propsQuery'],
    computed: {
        uniqueTeams() {
        // Get unique non-blank teams from allUsers
        return Array.from(new Set(this.allUsers.map(user => user.userSettings.team)))
            .filter(team => team !== ''); // Ensure team is a non-empty string
        },
        isClient() {
            const currentUserPermission = store.getters.getUserState.permission;
            const viewAsClient = store.getters.getViewAsClient;
            if (viewAsClient) {
                return true
            }
            return currentUserPermission == 'client-admin' || currentUserPermission == 'client-user';
        },
        filteredList() {
            const { paginationPage, itemsPerPage } = this;
            const { sortBy, direction } = this.sort;

            // Pagination
            const from = (paginationPage * itemsPerPage) - itemsPerPage;
            const to = paginationPage * itemsPerPage;

            let tempArr = this.filterUsers

            if (this.selectedTeam) {
                tempArr = tempArr.filter(user => user.userSettings.team === this.selectedTeam);
            }

            if (sortBy?.length > 0) {
                if (sortBy === 'lastActive') {
                    tempArr.sort((a, b) => {
                        if (direction === 'asc') {
                            return new Date(a.item.userSettings?.lastLoginTime || 0) - new Date(b.item.userSettings?.lastLoginTime || 0)
                        } else {
                            return new Date(b.item.userSettings?.lastLoginTime || 0) - new Date(a.item.userSettings?.lastLoginTime || 0)
                        }
                    });
                } else if (sortBy === 'accountSettings') {
                    tempArr.sort((a, b) => {
                        if (direction === 'asc') {
                            return (a?.userSettings?.accountSettings || []).length - (b?.userSettings?.accountSettings || []).length
                        } else {
                            return (b?.userSettings?.accountSettings || []).length - (a?.userSettings?.accountSettings || []).length
                        }
                    });
                } else if (sortBy === 'retainer') {
                    tempArr.sort((a, b) => {
                        if (direction === 'asc') {
                            return (a.userSettings.retainer?.length || 0) - (b.userSettings.retainer?.length || 0)
                        } else {
                            return (b.userSettings.retainer?.length || 0) - (a.userSettings.retainer?.length || 0)
                        }
                    });
                } else if (sortBy === 'team') {
                    tempArr.sort((a, b) => {
                        if (direction === 'asc') {
                            return TeamValueToLabel(a.userSettings.team, true).localeCompare(TeamValueToLabel(b.userSettings.team, true))
                        } else {
                            return TeamValueToLabel(b.userSettings.team, true).localeCompare(TeamValueToLabel(a.userSettings.team, true))
                        }
                    });
                } else if (sortBy === 'name') {
                    tempArr.sort((a, b) => {
                        if (direction === 'asc') {
                            return `${a.item.userSettings?.firstName} ${a.item.userSettings?.lastName}`.localeCompare(`${b.item.userSettings?.firstName} ${b.item.userSettings?.lastName}`)
                        } else {
                            return `${b.item.userSettings?.firstName} ${b.item.userSettings?.lastName}`.localeCompare(`${a.item.userSettings?.firstName} ${a.item.userSettings?.lastName}`)
                        }
                    });
                }
            }

            return tempArr.slice(from, to);
        },
        paginationPageCount() {
            const { itemsPerPage, } = this;
            return Math.ceil(this.filterUsers.length / itemsPerPage)
        },
        filterUsers() {
                const teamValue = this.allUsers.map(user => TeamValueToLabel(user?.userSettings?.team));
                const rolesValue = this.allUsers.map(user => this.roleValuesToLabel(user?.userSettings?.permission));

                const usersWithTeamRoles = this.allUsers.map((user, index) => ({
                    ...user,
                    teamValue: teamValue[index],
                    rolesValue: rolesValue[index],
                    fullName: `${user?.userSettings.firstName} ${user?.userSettings.lastName}`
                }));

                const fuse = new Fuse(usersWithTeamRoles, {
                    keys: ['userSettings.firstName', 'userSettings.lastName', 'userSettings.email', 'fullName'],
                    threshold: 0.1,
                    distance: 100,
                    includeScore: true,
                    minMatchCharLength: 1,
                });

                if (this.query && this.query.length > 0) {
                    return fuse.search(this.query || "");
                }

                return this.allUsers.map(val => ({
                    item: Object.assign(val, {}),
                    matches: [],
                    score: 0.4,
                }));
            }
    },
    async mounted() {

        const db = tenantDatabase();
        let userRef;
        const activeRetainerId = store.getters.getActiveRetainerState || 0;
        userRef = ref(db, `/users`)


        onValue(userRef, snapshot => {
            let rawData = snapshot.val();
            if (rawData) {
                let formattedData = Object.keys(rawData).map((key) => {
                    return {
                        uid: key,
                        userSettings: rawData[key]
                    }
                });
                let arr = formattedData;
                if (!this.isClient) {
                    arr = formattedData.filter(user => {
                        return user.userSettings.permission == 'admin'
                    })
                }
                arr.forEach((item, index) => {
                    if (item.userSettings.permission === 'client-admin') {
                        arr.splice(index, 1);
                        arr.unshift(item);
                    }
                });
                this.allUsers = arr;
            }
            this.isLoading = false;
        });
        this.sort.sortBy = 'name';
		this.sort.direction = 'asc';

    },
    methods: {
        handleFullReset() {
            this.query = ''
        },
        filterByTeam(team) {
            // Set the selected team
            this.selectedTeam = team;
            this.showModal = false;
        },
        clearSelection() {
            // Clear the selected team
            this.selectedTeam = null;
        },
        dropdownOptions(user) {
            if (user.userSettings && user.userSettings.team) {
                return TeamValueToLabel(user.userSettings.team, true);
            }
            return [];
        },
        showDetails() {
            this.showModal = !this.showModal;
        },
        handleSortBy(field) {
            const { direction, sortBy } = this.sort;
            this.sort.sortBy = field;
            if (sortBy === field) {
                this.sort.direction = direction === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort.direction = 'asc'
            }
        },
        handleSendEmailModalClose(success) {
            this.sendEmailModalActive = false
            if (success) {
                this.notifications = 'sendUserEmail'
            }
        },
        handlePaginationSelect(number) {
            this.paginationPage = number;
        },
        handlePaginationPrevNext(prev) {
            const { paginationPage } = this;
            this.paginationPage = prev ? paginationPage - 1 : paginationPage + 1;
        },
        roleValuesToLabel(value) {
            let role = allRoles.filter(item => {
                return item.value == value
            });
            return role[0] ? role[0].label : '';
        },
        handleEditUserLink(userObj) {
            const userString = JSON.stringify({ ...userObj });
            this.$router.push({
                name: 'Admin - User profile',
                params: {
                    data: userString
                }
            });
        },
        handleCopyEmail(email) {
            if (email) {
                navigator.clipboard.writeText(email).then(() => {
                    this.$notify({
                        title: "",
                        text: "Email address successfully copied to your clipboard",
                        duration: 5000,
                    });
                }, function (err) {
                    console.error('Async: Could not copy text: ', err);
                });
            }
        },
        convertDateString(ds, monthYear) {
            if (ds) {
                var options = monthYear ? { month: 'long', year: 'numeric' } : { month: 'short', day: 'numeric' };
                var date = new Date(ds);
                return date.toLocaleString('en-US', options)
            }
            return '—'
        },
        handleDeleteModalClose() {
            this.deleteModalActive = false;
            this.selectedUser = {};
        },
        handleDeleteUserModal(e) {
            this.deleteModalActive = true;
            this.selectedUser.userId = e.uid;
            this.selectedUser.user = e.userSettings;
            document.body.classList.add('overflow-hidden');
        },
        async sendConfirmationMail(user) {

            const auth = await getAuth();
            auth.onAuthStateChanged(currentUser => {
                if (currentUser) {

                    const currentToken = auth.currentUser.accessToken;
                    const currentUrlBase = window.location.origin;

                    let data = {
                        Uid: user.uid,
                        SiteUrl: currentUrlBase
                    }

                    this.isLoading = true;

                    axios.post(`${this.$root.apiBaseUrl}/account/confirmation`, data, {
                        headers: {
                            'authorization': currentToken,
                            'timezone': this.$root.apiTimezone
                        }
                    }).then(res => {
                        this.isLoading = false;
                        // Uses kyvg/vue3-notification 
                        this.$notify({
                            title: "Success",
                            text: "A confirmation email has been sent to the registered email.",
                            duration: 5000,
                        });
                    }).catch(err => {
                        console.log(err)
                        this.isLoading = false;
                    });

                }
            });
        }
    }
}
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.u-content-tight {
    max-width: 562px;
    margin: 0 auto;
}

.box-shadow {
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}
</style>
