<script setup>

import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { getAuth, getCurrentTeanantID, tenantDatabase } from "@/tenant";
import { getDatabase, ref, onValue, update } from "firebase/database";
import { getApp } from "firebase/app";
import axios from "axios";
import store from '../../store';
import initAllProjects from '@/helpers/InitAllProjects';
import DashboardEditItem from '@/components/DashboardEditItem.vue';

import Button from '../objects/Button.vue';
import Loading from '../containers/Loading.vue';

import deleteUser from "../../helpers/DeleteUser";
import Close from '~icons/my-icons/cross';
import ClientManagerDropdown from "@/components/objects/ClientManagerDropdown.vue";
import ConfirmationDialog from "../containers/ConfirmationDialog.vue";

import { Skeletor } from "vue-skeletor";

import LogAuditLog from "@/helpers/logAuditLog";

</script>

<template>
    <div class="fixed top-0 left-0 w-full min-h-screen h-full overflow-auto bg-grey-900/40 z-[100]">
        <!-- <div class="absolute top-0 left-0 w-full h-full"></div> -->
        <div class="max-w-[800px] w-full bg-grey-50 ml-auto h-screen flex flex-col z-[2] relative">
            <div class="px-6 py-5 border-b border-grey-200 bg-white flex justify-between ">
                <h3 class="font-bold text-lg col">{{ $route.params.userid ? 'User:' : 'Create User' }} <span v-if="$route.params.userid" class="font-normal">{{ user.firstName }} {{ user.lastName }}</span></h3>
                <Close @click="$router.go(-1)" class="cursor-pointer" />
            </div>
            <div class="w-full flex flex-col overflow-auto h-full">
                <div v-if="notifications.message"
                    class="max-w-[840px] w-full mx-auto bg-orange-100 rounded-lg px-4 py-4 mt-8 flex items-center">
                    <div
                        class="flex-shrink-0 mt-1 text-white mr-3 rounded-full bg-orange-400 w-7 h-7 inline-flex items-center justify-center text-sm">
                        !
                    </div>
                    <div class="pl-3 text-xs">
                        <p>{{ notifications.message }}</p>
                    </div>
                    <button class="ml-auto mr-0 font-medium text-[9px] text-orange-400"
                        @click="notifications.message = ''">
                        <Close />
                    </button>
                </div>
                <form class="p-6 flex flex-col justify-between relative" ref="addContact">
                    <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                        <div v-if="!loadingUser" class="w-full mx-auto bg-white rounded-lg border-solid border">
                            <div class="py-4 px-6 border-b border-grey-200">
                                <h1 class="text-sm font-bold">Details</h1>
                            </div>
                            <div class="p-6">
                                <div v-if="$route.params.userid">
                                    <DashboardEditItem
                                        :val="user.lastLoginTime || 'Never logged in'"
                                        :init-value="user.lastLoginTime || 'Never logged in'"
                                        theme="dark"
                                        name="Last active"
                                        label="Last active"
                                        input-type="html">
                                            <div class="text-xs w-full">
                                                {{ user.lastLoginTime || 'Never logged in' }}
                                            </div>
                                        </DashboardEditItem>
                                    <DashboardEditItem
                                        theme="dark"
                                        name="Password"
                                        label="Password"
                                        input-type="html">
                                        <div class="text-xs w-full">
                                            <router-link
                                            :to="{ name: 'ChangePassword', params: { userid: $route.params.userid } }"
                                            class="inline-block mr-6 text-blue-500 hover:underline">Change
                                            password</router-link>
                                        </div>
                                    </DashboardEditItem>
                                    <DashboardEditItem
                                        theme="dark"
                                        name="Delete user"
                                        label="Delete user"
                                        input-type="html">
                                        <div class="text-xs w-full">
                                            <div class="text-red-500 hover:underline cursor-pointer"
                                            @click="handleDeleteUserModal()">Delete Account</div>
                                        </div>
                                    </DashboardEditItem>
                                    <hr class="bg-grey-100 h-px w-full my-4">
                                </div>
                                <DashboardEditItem
                                    v-model:val="user.email"
                                    :init-value="user.email"
                                    :required="true"
                                    theme="dark"
                                    name="Email"
                                    label="Email"
                                    :input-type="$route.params.userid ? 'static' : 'email'" />
                                <DashboardEditItem
                                    v-model:val="user.firstName"
                                    :init-value="user.firstName"
                                    :required="true"
                                    theme="dark"
                                    name="First name"
                                    label="First name"
                                    input-type="text" />
                                    <div v-if="firstNameError" class="flex ml-[125px] pl-4 mb-2 text-error text-sm">{{ firstNameError }}</div>
                                <DashboardEditItem
                                    v-model:val="user.lastName"
                                    :init-value="user.lastName"
                                    :required="true"
                                    theme="dark"
                                    name="Last name"
                                    label="Last name"
                                    input-type="text" />
                                    <div v-if="lastNameError" class="flex ml-[125px] pl-4 mb-2 text-error text-sm">{{ lastNameError }}</div>
                                <DashboardEditItem
                                    v-model:val="user.roleTitle"
                                    :init-value="user.roleTitle"
                                    theme="dark"
                                    name="Role title"
                                    label="Role title"
                                    input-type="text" />
                                <DashboardEditItem
                                    v-model:val="user.phone"
                                    :init-value="user.phone"
                                    :required="true"
                                    theme="dark"
                                    name="Contact number"
                                    label="Contact number"
                                    input-type="text" />
                            </div>
                        </div>
                        <Skeletor v-else class="rounded mt-4" width="100%" height="140" />
                        <div
                            class="w-full mx-auto mt-4 bg-white rounded-lg border-solid border"
                            v-if="sortedFirebaseClients && sortedFirebaseClients.length > 0"
                        >
                            <div class="py-4 px-6 border-b border-grey-200">
                                <h1 class="text-sm font-bold">Responsibilities</h1>
                            </div>
                            <div class="flex flex-col gap-4 p-6">
                                <div class="text-xs text-grey-900 font-bold pb-3">
                                    Account manager for clients:
                                </div>
                                <ClientManagerDropdown
                                    :activeClients="activeClients"
                                    :isActiveLabel="isActiveLabel"
                                    :allClients="sortedFirebaseClients"
                                    @handleAddUser="userId => addUser(userId)"
                                    @handleRemoveUser="userId => removeUser(userId)"
                                    />
                            </div>
                        </div>
                        <Skeletor v-else class="rounded mt-4" width="100%" height="140" />
                    </Loading>
                </form>
            </div>
            <div
                class="text-right py-3 px-6 flex items-center justify-end mt-auto bg-white border-t border-grey-200 w-full">
                <v-btn @click="$router.go(-1)" class="text-blue-500 mr-6 cursor-pointer">
                    Cancel
                </v-btn>
                <button :disabled="isSubmitting" class="px-6 py-3 text-white bg-orange-500 rounded font-medium"
                    @click="submitClickHandler" type="button">
                    Save
                </button>
            </div>
        </div>
        <ConfirmationDialog :isActive="deleteModalActive"
            headerText="Are you sure you want to delete this account?"
            :descriptionText="deleteUserModalDescription" confirmButtonText="Delete account"
            @handleModalClose="handleDeleteModalClose" @handleModalConfirm="handleDeleteUserModalConfirm" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            currentUserRole: store.getters.getUserState.permission,
            allTeams: [],
            allRetainers: [],
            user: {
                firstName: "",
                lastName: "",
                email: "",
                // password: "",
                phone: "",
                roleTitle: "",
                permission: 'admin',
                responsibilities: {},
                userCanLogin: false,
            },
            error: {
                password: false,
                confirmPassword: false
            },
            notifications: {
                message: ""
            },
            confirmPassword: "",
            deleteModalActive: false,
            loadingUser: true,
            isSubmitting: false,
            firebaseClients: [],
            firstNameError: '',
            lastNameError: '',
        }
    },
    computed: {
        activeClients() {
            const allClientIds = this.sortedFirebaseClients?.map(c => c.clientId);
            const clients = Object.keys(this.user.responsibilities)?.filter(clientId => allClientIds.includes(clientId) == true);
            return clients
        },
        deleteUserModalDescription() {
            const { user } = this;
            return `<span class="block mb-1">Name: <span class="font-medium">${user?.firstName} ${user?.lastName}</span></span>
                    <span class="block">Email: <span class="font-medium">${user?.email}</span></span>`;
        },
        sortedFirebaseClients() {
            return this.firebaseClients
                .filter(client => !client.archived)
                .sort((a, b) => a?.name?.localeCompare(b.name)) || [];
        },
        validatePassword() {
            // Password validation
            if (this.user.password.length > 0) {
                const regexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/g;
                if (!regexString.test(this.user.password)) {
                    this.error.password = true;
                    return false;
                }
            } else {
                this.error.password = true;
                return false;
            }
            this.error.password = false;
            return true;
        },
        validateConfirmPassword() {
            if (this.user.password === this.confirmPassword) {
                this.error.confirmPassword = false;
                return true;
            }
            this.error.confirmPassword = true;
            return false;
        }
    },
    created() {
        const user = store.getters.getUserState;
        if (user.permission == 'client-user') {
            if (!user.accountSettings || !user.accountSettings.includes('can-add-users')) {
                this.$router.push({ name: "Users" })
            }
        }
    },
    async mounted() {
        const auth = await getAuth();
        this.getUserData()

        axios({
            method: 'get',
            url: `${this.$root.apiBaseUrl}/client/all`,
            headers: {
                'authorization': auth.currentUser.accessToken,
                'timezone': this.$root.apiTimezone
            },
        }).then(res => {
            this.allTeams = res.data;
            this.getRetainerProjects();
        }).catch(err => {
            console.log(err);
        });

        if (this.$route.params.userid) {
            const db = tenantDatabase();
            const userRef = ref(db, `/users/${this.$route.params.userid}`);
            onValue(userRef, (snapshot) => {
                if (snapshot.exists()) {
                    const user = snapshot.val();
                    this.user = {
                        ...user,
                    };
                    
                    this.loadingUser = false
                } else {
                    console.log("No data available");
                    return {}
                }
            })
        } else {
            this.loadingUser = false
        }
    },
    methods: {
        isActiveLabel(clientId) {
            return !!this.user.responsibilities?.[clientId];
        },
        handleDeleteModalClose() {
            this.deleteModalActive = false;
        },
        async handleDeleteUserModalConfirm() {
            this.submitting = true;
            this.$notify({
                title: "User deleted",
                text: "User successfully deleted",
                duration: 5000,
            });
            this.$router.go(-1)
            await deleteUser(this.$route.params.userid, this.user);
            this.deleteModalActive = false;
            this.submitting = false;
        },
        handleDeleteUserModal() {
            this.deleteModalActive = true;
            document.body.classList.add('overflow-hidden');
        },
        removeUser(uid) {
            if (!this.user.responsibilities) {
                return;
            }

            delete this.user.responsibilities[uid]
        },
        addUser(uid) {
            if (!this.user.responsibilities) {
                this.user.responsibilities = {};
            }

            if (this.user.responsibilities[uid]) {
                this.removeUser(uid)
                return;
            }

            this.user.responsibilities[uid] = true;
        },
        async getUserData() {
            const auth = await getAuth();
            const once = auth.onAuthStateChanged(user => {
                if (user) {
                    this.authUserUID = user.uid;
                }
            });

            once();

            await initAllProjects();

            const db = tenantDatabase();

            const clientsRef = ref(db, `/clients`);

            onValue(clientsRef, (snapshot) => {
                if (snapshot.exists()) {
                    const clientsObject = snapshot.val()
                    this.firebaseClients = Object.keys(clientsObject).map(key => {
                        return {
                            ...clientsObject[key],
                            clientId: key
                        }
                    });

                    this.isLoading = false;
                } else {
                    console.log("No data available");
                    return {}
                }
            })
        },
        handleCheckboxUpdate(value) {
            let settings = this.user.accountSettings;
            if (settings.filter(a => a === value).length > 0) {
                this.user.accountSettings = settings.filter(a => a !== value);
            } else {
                let arr = settings;
                arr.push(value);
                this.user.accountSettings = arr;
            }
        },
        handleUpdate(event) {
            this.user.retainer = event
        },
        handleConfirmPassword(e) {
            this.confirmPassword = e.target.value;
            if (this.confirmPassword === this.user.password) {
                this.error.confirmPassword = false;
            } else {
                this.error.confirmPassword = true;
            }
        },
        formatRetainers(retainerList) {
            const retainersArr = retainerList.map(item => {
                return {
                    value: item.id,
                    label: item.projectName
                }
            });
            return retainersArr;
        },
        async  getRetainerProjects($event) {

            // this.$refs.multiselect.clear()
            this.allRetainers = [];
            this.user.retainer = [];

            this.user.team = $event ? $event?.target.value : '';

            if (this.user.team == 'internal-user') {
                this.allRetainers = store.getters.getAllProjects.map(item => {
                    return {
                        label: item.projectName,
                        value: item.id
                    }
                });
            } else {
                if (this.user.team) {

                    const auth = await getAuth();
                    axios({
                        method: 'get',
                        url: `${this.$root.apiBaseUrl}/project/list/${this.user.team}`,
                        headers: {
                            'authorization': auth.currentUser.accessToken,
                            'timezone': this.$root.apiTimezone
                        }
                    }).then(res => {
                        const retainers = res.data;
                        if (retainers.length > 0) {
                            const allRetainersArr = this.formatRetainers(retainers);
                            this.allRetainers = allRetainersArr;
                            if (this.currentUserRole === 'client-admin') {
                                const arr = allRetainersArr.filter(item => {
                                    return store.getters.getUserState.retainer.includes(item.value);
                                });
                                this.user.retainer = arr;
                            } else {
                                this.user.retainer = [];
                            }
                        } else {
                            this.allRetainers = []
                        }
                    }).catch(err => {
                        console.log(err)
                    });
                } else {
                    this.allRetainers = [];
                    this.user.team = "";
                    this.user.retainer = [];
                }
            }
        },
        async submitClickHandler() {
            this.firstNameError = this.user.firstName ? '' : 'First name is required.';
            this.lastNameError = this.user.lastName ? '' : 'Last name is required.';

            if (this.firstNameError || this.lastNameError) {
                return;
            }
            const form = this.$refs.addContact;
            // let val = false;
            // if(!this.validatePassword || !this.validateConfirmPassword) {
            //     return;
            // }
            if (form.reportValidity()) {

                this.isSubmitting = true;
                const dataObj = {
                    ...this.user,
                    responsibilities: this.user.responsibilities,
                    permission: 'admin',
                    tenantId: await getCurrentTeanantID()
                };

                const resetClientRelations = (this.firebaseClients || [])?.reduce((acc, val) => {
                    return {
                        ...acc,
                        [`/clients/${val.clientId}/accountManagers/${this.$route.params.userid}`]: null
                    }
                }, {});

                const clientRelations = Object.keys(this.user.responsibilities).reduce((acc, val) => {
                    return {
                        ...acc,
                        [`/clients/${val}/accountManagers/${this.$route.params.userid}`]: true
                    }
                }, {});

                if (this.$route.params.userid) {
                    const db = tenantDatabase();

                    update(ref(db), {
                        ...resetClientRelations,
                        ...clientRelations,
                        [`/users/${this.$route.params.userid}`]: dataObj
                    }).then(() => {
                        LogAuditLog({
                            type: "User",
                            object: "User",
                            action: "edit",
                            data: {
                                id: this.$route.params.userid,
                                ...dataObj
                            }
                        })
                        this.isSubmitting = false;
                        this.$notify({
                            title: "",
                            text: "User updated successfully",
                            duration: 5000,
                        });
                        this.$router.push({ name: "Admin Users" });
                    });

                } else {
                    const app = getApp();
                    const functions = getFunctions(app);
                    // connectFunctionsEmulator(functions, "localhost", 5001);
                    
                    const createUser = httpsCallable(functions, "createNewAdmin");
                    createUser({ data: dataObj }).then(result => {
                        if (result.data?.message?.errorInfo) {
                            this.notifications.message = result.data.message.errorInfo.message;
                            this.isSubmitting = false;
                        } else if (result.data.errorInfo) {
                            this.notifications.message = result.data.errorInfo.message;
                            this.isSubmitting = false;
                        } else {
                            this.isSubmitting = false;
                            this.$router.push({ name: "Admin Users" });
                            this.$notify({
                                title: "",
                                text: "User created successfully",
                                duration: 5000,
                            });
                            LogAuditLog({
                                type: "User",
                                object: "User",
                                action: "create",
                                data: {
                                    id: result.data.uid,
                                    ...dataObj
                                }
                            })
                        }
                        this.sendConfirmationMail(result.data);    
                    }).catch(err => {
                        this.isSubmitting = false;
                        console.log(err)
                    });
                }

                return;
            }

        },
        async sendConfirmationMail(user) {

            const auth = await getAuth();
            auth.onAuthStateChanged(currentUser => {
                if (currentUser) {

                    const currentToken = auth.currentUser.accessToken;
                    const currentUrlBase = window.location.origin;

                    let data = {
                        Uid: user.uid,
                        SiteUrl: currentUrlBase
                    }

                    axios.post(`${this.$root.apiBaseUrl}/account/confirmation`, data, {
                        headers: {
                            'authorization': currentToken,
                            'timezone': this.$root.apiTimezone
                        }
                    }).then(res => {
                        // alert('sent');
                    }).catch(err => {
                        console.log(err)
                    });

                }
            });
        }
    }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.multiselect {
    height: 48px;

    .multiselect-multiple-label {
        overflow: hidden;
        display: inline;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 48px;
        width: 100%;
        vertical-align: middle;
    }
}
</style>
